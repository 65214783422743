<template>
    <section id="contacto">
      <b-container fluid class="py-3">
        <!-- <b-row class="d-flex justify-content-center mb-4 contacto-title">
            CONTACTO
        </b-row> -->
        <b-row class="d-flex justify-content-md-center mb-md-2">
          <b-col class="d-flex justify-content-center text-center">
            <h3>¿Te interesa ser parte? <br>¡Completa <a class="formulario-link-color" href="https://forms.gle/BN9d4XvyyYmYgB4SA" target="_blank">este formulario</a> y estaremos en contacto!</h3>
          </b-col>
        </b-row>
        <b-row class="d-flex justify-content-md-center">
          <b-col>
            <div class="d-flex align-items-center justify-content-md-center mt-1">
              <div class="circle d-flex justify-content-center align-items-center">
                <b-img :src="require('../../src/assets/iconmonstr-phone-1.svg')"></b-img>
              </div>
              <div class="ml-3 mt-2">
                <h6>LLÁMANOS</h6>
                <h6>32 2183656</h6>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex align-items-center justify-content-md-center mt-2 mt-md-1 pr-md-3">
              <div class="circle d-flex justify-content-center align-items-center">
                <b-img :src="require('../../src/assets/iconmonstr-whatsapp-1.svg')"></b-img>
              </div>
              <div class="ml-3 mt-2">
                <a href="https://wa.me/56932327051">
                  <h6>WHATSAPP</h6>
                  <h6>+569 32327051</h6>
                </a>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex align-items-center justify-content-md-center mt-2 mt-md-1">
              <a href="mailto:contacto@igniscowork.cl">
                <div class="circle d-flex justify-content-center align-items-center">
                  <b-img :src="require('../../src/assets/iconmonstr-email-4.svg')"></b-img>
                </div>
              </a>
              <div class="ml-3 mt-2">
                <a href="mailto:contacto@igniscowork.cl">
                  <h6>ESCRÍBENOS</h6>
                  <h6>contacto@igniscowork.cl</h6>
                </a>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex align-items-center justify-content-md-center mt-2 mt-md-1">
              <a href="https://goo.gl/maps/2urwUPw12kjx6gUb9" target="_blank">
                <div class="circle d-flex justify-content-center align-items-center">
                  <b-img :src="require('../../src/assets/iconmonstr-location-2.svg')"></b-img>
                </div>
              </a>
              <div class="ml-3 mt-2">
                <a href="https://goo.gl/maps/2urwUPw12kjx6gUb9" target="_blank">
                  <h6>VISÍTANOS</h6>
                  <h6>Av. Libertad 919 oficina 31, Viña del Mar</h6>
                </a>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex align-items-center justify-content-center mt-3 mt-md-2">
              <a href="https://www.instagram.com/igniscowork/" target="_blank" class="mr-3"><font-awesome-icon size="2x" :style="{ color: 'white' }" :icon="['fab', 'instagram']" /></a>
              <a href="https://www.facebook.com/igniscowork" target="_blank" class="mr-3"><font-awesome-icon size="2x" :style="{ color: 'white' }" :icon="['fab', 'facebook-square']" /></a>
              <a href="https://www.linkedin.com/company/ignis-cowork-chile/" target="_blank"><font-awesome-icon size="2x" :style="{ color: 'white' }" :icon="['fab', 'linkedin']" /></a>
            </div>
          </b-col>
          <!-- <b-col md="6" class="d-flex justify-content-center justify-content-md-end">
            <b-form @submit="onSubmit" class="resp-vw">
              <b-input-group>
                <b-form-input
                  class="mr-2 mb-2"
                  id="input-nombre"
                  v-model="form.nombre"
                  placeholder="Nombre"
                  required
                ></b-form-input>
                <b-form-input
                  id="input-email"
                  type="email"
                  v-model="form.email"
                  placeholder="Correo electrónico"
                  required
                ></b-form-input>
              </b-input-group>
              <b-form-textarea
                class="mb-2"
                id="input-mensaje"
                v-model="form.mensaje"
                rows="7"
                placeholder="Mensaje"
                required
              ></b-form-textarea>
              <b-button class="w-100" type="submit" variant="light">ENVIAR</b-button>
            </b-form>
          </b-col>
          <b-col md="6">
            <div class="d-flex align-items-center justify-content-start mt-4">
              <div class="circle d-flex justify-content-center align-items-center">
                <b-img class="llamanos-icono" :src="require('../../src/assets/iconmonstr-phone-1.svg')"></b-img>
              </div>
              <div class="ml-3 mt-2">
                <h6>LLÁMANOS</h6>
                <h6>+569 96439455</h6>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-start mt-3">
              <a href="mailto:contacto@igniscowork.cl">
                <div class="circle d-flex justify-content-center align-items-center">
                  <b-img class="llamanos-icono" :src="require('../../src/assets/iconmonstr-email-4.svg')"></b-img>
                </div>
              </a>
              <div class="ml-3 mt-2">
                <a href="mailto:contacto@igniscowork.cl">
                  <h6>ESCRÍBENOS</h6>
                  <h6>contacto@igniscowork.cl</h6>
                </a>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-start mt-3">
              <a href="https://goo.gl/maps/2urwUPw12kjx6gUb9" target="_blank">
                <div class="circle d-flex justify-content-center align-items-center">
                  <b-img class="llamanos-icono" :src="require('../../src/assets/iconmonstr-location-2.svg')"></b-img>
                </div>
              </a>
              <div class="ml-3 mt-2">
                <a href="https://goo.gl/maps/2urwUPw12kjx6gUb9" target="_blank">
                  <h6>VISÍTANOS</h6>
                  <h6>Av. Libertad 919 oficina 31, Viña del Mar</h6>
                </a>
              </div>
            </div>
            <div class="d-flex justify-content-center justify-content-md-start mt-5 ml-1">
              <a href="https://www.instagram.com/igniscowork/" target="_blank" class="mr-3"><font-awesome-icon size="2x" :style="{ color: 'white' }" :icon="['fab', 'instagram']" /></a>
              <a href="https://www.facebook.com/igniscowork" target="_blank" class="mr-3"><font-awesome-icon size="2x" :style="{ color: 'white' }" :icon="['fab', 'facebook-square']" /></a>
              <a href="https://www.youtube.com/channel/UCS9-l7NAW3atvO288NIeK1w" target="_blank"><font-awesome-icon size="2x" :style="{ color: 'white' }" :icon="['fab', 'youtube']" /></a>
            </div>
          </b-col> -->
        </b-row>
      </b-container>
    </section>
</template>

<script>
export default {
    name: 'Contacto',
    data() {
      return {
        form: {
          nombre: '',
          email: '',
          mensaje: ''
        }
      }
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
        alert(JSON.stringify(this.form))
      }
    }
}
</script>

<style>
#contacto {
  background-color: #F4A836;
}

#contacto h6 {
  color: white;
  line-height: 0.8rem;
}

#contacto h3 {
  color: white;
}

#contacto a:hover {
  color: black;
  text-decoration: none;
}

.formulario-link-color {
  color: rgb(25, 25, 145);
}

.contacto-title {
    font-size: 36px;
    color: white;
}

.circle
{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: white;
}

.btn-light {
  color: #d19740 !important;
}

.form-control {
  border: none !important;
  border-radius: 0% !important;
  background-color: #F8C477 !important;
}

@media (min-width: 1024px) {
    .resp-vw {
        width: 67% !important;
    }
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    white !important;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    white !important;
   opacity:  1 !important;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    white !important;
   opacity:  1 !important;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    white !important;
}
::-ms-input-placeholder { /* Microsoft Edge */
   color:    white !important;
}

::placeholder { /* Most modern browsers support this now. */
   color:    white !important;
}
</style>