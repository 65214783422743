<template>
    <section id="servicios">
      <b-modal centered
        v-model="show"
        size="xl"
        id="modal-1"
        hide-header
        hide-footer
      >
        <b-row>
          <b-col class="m-0 p-0" md="6">
            <b-card-img class="p-2" :src="require('../../src/assets/galeria-fotos/1.webp')" alt="Image" bottom></b-card-img>
          </b-col>
          <b-col class="m-0 p-0" md="6">
            <b-card-img class="p-2" :src="require('../../src/assets/galeria-fotos/2.webp')" alt="Image" bottom></b-card-img>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="m-0 p-0" md="6">
            <b-card-img class="p-2" :src="require('../../src/assets/galeria-fotos/3.webp')" alt="Image" bottom></b-card-img>
          </b-col>
          <b-col class="m-0 p-0" md="6">
            <b-card-img class="p-2" :src="require('../../src/assets/galeria-fotos/4.webp')" alt="Image" bottom></b-card-img>
          </b-col>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <b-button @click="show=false" class="cerrar-btn">CERRAR GALERÍA</b-button>
        </b-row>
      </b-modal>
      <b-container fluid class="pt-3">
        <b-row class="d-flex justify-content-center mb-3 mb-md-4 servicios-title text-center mx-3 mx-md-0">
            NUESTROS SERVICIOS
        </b-row>
        <b-row class="d-flex justify-content-center align-items-center text-center mb-3 mb-md-4 mx-3 mx-md-0">
          <!-- <div class="pb-2 pb-md-0 pr-md-5">
            <div style="margin-left:55px;" class="pb-2">
              <font-awesome-icon size="5x" :icon="['fa', 'exclamation-triangle']" />
            </div>
            <p>PÁGINA EN MANTENCIÓN</p>
          </div> -->
          <b-col md="6">
            <p>
              Buscamos entregar una experiencia de calidad, brindando un servicio personalizado, 
              con diferentes espacios y modalidades que se adapten a tus necesidades específicas. 
              Un lugar donde tú manejas tu propio horario, de acuerdo con tus metas profesionales 
              y tu ritmo de trabajo. <a href="https://igniscowork.cl/brochure_IgnisCowork_2024.pdf" target="_blank">Descarga aquí nuestro brochure</a>.
            </p>
          </b-col>
        </b-row>
        <b-row id="cards" class="mb-4 margen-cards-servicios">
          <b-col class="mb-2 d-flex justify-content-center" sm="6" md="6" lg="4" xl="4">
            <b-card
              no-body
              bg-variant="light"
              class="no-borders"
            >
              <b-card-header class="d-flex align-items-center justify-content-center card-header-height">
                <h4 class="mb-0 color-naranjo">SALA <b>EL ENCUENTRO</b></h4>
              </b-card-header>
              <b-card-img :src="require('../../src/assets/16-9_1encuentro_peq.jpg')" alt="Image" bottom></b-card-img>
              <b-card-body class="flex-column h-100" style="overflow-y: auto;">
                <div class="d-flex align-items-center">
                  <b-card-text>
                    <div v-b-toggle.collapse-1-inner>
                      <p><b>DESCRIPCIÓN</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-1-inner">
                      <p>Con capacidad máxima de 12 personas, esta es la sala principal de nuestro cowork. Cálida y bien iluminada, cuenta con escritorios individuales con toma de corriente, y un espacio personal para dejar tus pertenencias.</p>
                    </b-collapse>
                    <div v-b-toggle.collapse-2-inner>
                      <p><b>HORARIO</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-2-inner">
                      <p>Lunes a Viernes, de 9h a 18h.</p>
                    </b-collapse>
                    <div v-b-toggle.collapse-3-inner>
                      <p><b>SERVICIOS INCLUIDOS</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-3-inner">
                      <div class="line-height-one">
                        <p>Acceso a internet de alta velocidad.</p>
                        <p>Acceso a escáner.</p>
                        <p>Agua mineral, té y café ilimitados.</p>
                        <p>Uso de cafetería.</p>
                        <p>Estufa eléctrica.</p>
                        <p style="margin-bottom: 31px;">Seguridad y limpieza diaria.</p>
                      </div>
                    </b-collapse>
                    <div v-b-toggle.collapse-4-inner>
                      <p><b>SERVICIOS EXTRA*</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-4-inner">
                      <div class="line-height-one">
                        <p>Impresiones.</p>
                        <p>Locker con llave.</p>
                        <p>Uso de sala de reuniones.</p>
                        <p>Participación en eventos.</p>
                        <p>Horario extendido 24/7.</p>
                        <p>Estacionamiento.</p>
                        <p>Oficina Virtual.</p>

                        <p style="line-height: 1rem; font-size: 0.85rem;"><i>*Servicios extra: Valor de servicios no incluidos en tarifa base. Servicios sujetos a disponibilidad.</i></p>
                      </div>
                    </b-collapse>
                    <div v-b-toggle.collapse-5-inner>
                      <p><b>MODALIDADES</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-5-inner">
                      <div class="line-height-one">
                        <p>Hora</p>
                        <p>Pase diario</p>
                        <p>Pase semanal</p>
                        <p>Planes mensuales</p>
                        <p>Planes flexibles</p>
                      </div>
                    </b-collapse>
                    <div v-b-toggle.collapse-6-inner>
                      <p><b>TARIFAS</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-6-inner">
                      <div class="line-height-one">
                        <p>Hora	<b class="right">0,12 UF</b></p>
                        <p>Pase diario <b class="right">0,4 UF</b></p>
                        <p style="margin-bottom: 1.2rem;">Pase semanal <b class="right">1,2 UF</b></p>
                        <p style="line-height: 1rem;">Para información sobre nuestros planes, <a href="https://igniscowork.cl/1sala_encuentro_2.pdf" target="_blank">descargue nuestro brochure.</a></p>
                        <p style="line-height: 1rem; font-size: 0.85rem;"><i>*Todos los valores tienen el IVA incluido.</i></p>
                      </div>
                    </b-collapse>
                  </b-card-text>
                </div>
              </b-card-body>
              <!-- <b-button v-b-modal.modal-1 @click="actualizarImagenes(1)" variant="light" class="w-100">VER FOTOS</b-button> -->
            </b-card>
          </b-col>
          <b-col class="mb-2 d-flex justify-content-center" sm="6" md="6" lg="4" xl="4">
            <b-card
              no-body
              bg-variant="light"
              class="no-borders"
            >
              <b-card-header class="d-flex align-items-center justify-content-center card-header-height">
                <h4 class="mb-0 color-naranjo">SALA <b>CONCENTRACIÓN</b></h4>
              </b-card-header>
              <b-card-img :src="require('../../src/assets/16-9_2concentración_peq.jpg')" alt="Image" bottom></b-card-img>
              <b-card-body class="flex-column h-100" style="overflow-y: auto;">
                <div class="d-flex align-items-center">
                  <b-card-text>
                    <div v-b-toggle.collapse-7-inner>
                      <p><b>DESCRIPCIÓN</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-7-inner">
                      <p>Con capacidad máxima de 5 personas, esta es una sala compartida pequeña, que brinda 
                        mayor privacidad. Cuenta con 5 estaciones de trabajo individuales con toma de corriente, 
                        pizarras de corcho y un espacio personal para dejar tus pertenencias. Ideal para grupos de trabajo.</p>
                    </b-collapse>
                    <div v-b-toggle.collapse-8-inner>
                      <p><b>HORARIO</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-8-inner">
                      <p>Lunes a Viernes, de 9h a 18h.</p>
                    </b-collapse>
                    <div v-b-toggle.collapse-9-inner>
                      <p><b>SERVICIOS INCLUIDOS</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-9-inner">
                      <div class="line-height-one">
                        <p>Acceso a internet de alta velocidad.</p>
                        <p>Acceso a escáner.</p>
                        <p>Agua mineral, té y café ilimitados.</p>
                        <p>Uso de cafetería.</p>
                        <p>Estufa eléctrica.</p>
                        <p style="margin-bottom: 31px;">Seguridad y limpieza diaria.</p>
                      </div>
                    </b-collapse>
                    <div v-b-toggle.collapse-10-inner>
                      <p><b>SERVICIOS EXTRA*</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-10-inner">
                      <div class="line-height-one">
                        <p>Impresiones.</p>
                        <p>Uso de sala de reuniones.</p>
                        <p>Participación en eventos.</p>
                        <p>Horario extendido 24/7.</p>
                        <p>Estacionamiento.</p>
                        <p>Oficina Virtual.</p>

                        <p style="line-height: 1rem; font-size: 0.85rem;"><i>*Servicios extra: Valor de servicios no incluidos en tarifa base. Servicios sujetos a disponibilidad.</i></p>
                      </div>
                    </b-collapse>
                    <div v-b-toggle.collapse-11-inner>
                      <p><b>MODALIDADES</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-11-inner">
                      <div class="line-height-one">
                        <p style="margin-bottom: -20px;">Escritorio individual:
                          <ul>
                            <li><p>Hora</p></li>
                            <li><p>Pase diario</p></li>
                          </ul>
                        </p>
                        <p>Sala completa:
                          <ul>
                            <li><p>Hora</p></li>
                            <li><p>Medio día</p></li>
                            <li><p>Día completo</p></li>
                            <li><p>Plan Mensual</p></li>
                          </ul>
                        </p>
                      </div>
                    </b-collapse>
                    <div v-b-toggle.collapse-12-inner>
                      <p><b>TARIFAS</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-12-inner">
                      <div class="line-height-one">
                        <p style="margin-bottom: -20px;">Escritorio individual:
                          <ul>
                            <li><p>Hora	<b class="right">0,15 UF</b></p></li>
                            <li><p>Pase diario <b class="right">0,5 UF</b></p></li>
                          </ul>
                        </p>
                        <p style="margin-bottom: -27px;">Sala completa:
                          <ul>
                            <li><p>Hora	<b class="right">0,5 UF</b></p></li>
                            <li><p>Medio día <b class="right">1 UF</b></p></li>
                            <li><p>Día completo <b class="right">1,5 UF</b></p></li>
                            <li><p>Plan mensual <b class="right">13 UF</b></p></li>
                          </ul>
                        </p>

                        <p style="line-height: 1rem;">Para más detalles sobre este servicio, <a href="https://igniscowork.cl/2concentracion.pdf" target="_blank">descargue nuestro brochure.</a></p>
                        <p style="line-height: 1rem; font-size: 0.85rem;"><i>*Todos los valores tienen el IVA incluido.</i></p>
                      </div>
                    </b-collapse>
                  </b-card-text>
                </div>
              </b-card-body>
              <!-- <b-button v-b-modal.modal-1 @click="actualizarImagenes(2)" variant="light" class="w-100">VER FOTOS</b-button> -->
            </b-card>
          </b-col>
          <b-col class="mb-2 d-flex justify-content-center" sm="6" md="6" lg="4" xl="4">
            <b-card
              no-body
              bg-variant="light"
              class="no-borders"
            >
              <b-card-header class="d-flex align-items-center justify-content-center card-header-height">
                <h4 class="mb-0 color-naranjo">SALAS DE <b>REUNIONES</b></h4>
              </b-card-header>
              <b-card-img :src="require('../../src/assets/16-9_3confluencia_peq.jpg')" alt="Image" bottom></b-card-img>
              <b-card-body class="flex-column h-100" style="overflow-y: auto;">
                <div class="d-flex align-items-center">
                  <b-card-text>
                    <div v-b-toggle.collapse-13-inner>
                      <p><b>DESCRIPCIÓN</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-13-inner">
                      <p>Nuestras salas de reuniones se encuentran equipadas con amplias mesas, cómodas sillas, 
                        pizarras de vidrio y Smart TV con conexión HDMI o inalámbrica. 
                        Capacidad: 6 a 8 personas. Su uso es con previa reserva.</p>
                    </b-collapse>
                    <div v-b-toggle.collapse-14-inner>
                      <p><b>HORARIO</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-14-inner">
                      <p>Lunes a Viernes, de 9h a 18h.</p>
                    </b-collapse>
                    <div v-b-toggle.collapse-15-inner>
                      <p><b>SERVICIOS INCLUIDOS</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-15-inner">
                      <div class="line-height-one">
                        <p>Acceso a internet de alta velocidad.</p>
                        <p>Acceso a escáner.</p>
                        <p>Agua mineral, té y café ilimitados.</p>
                        <p>Uso de cafetería.</p>
                        <p>Estufa eléctrica.</p>
                        <p style="margin-bottom: 31px;">Seguridad y limpieza diaria.</p>
                      </div>
                    </b-collapse>
                    <div v-b-toggle.collapse-16-inner>
                      <p><b>SERVICIOS EXTRA*</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-16-inner">
                      <div class="line-height-one">
                        <p>Estacionamiento.</p>

                        <p style="line-height: 1rem; font-size: 0.85rem;"><i>*Servicios extra: Valor de servicios no incluidos en tarifa base. Consulta disponibilidad, tarifas y condiciones.</i></p>
                      </div>
                    </b-collapse>
                    <div v-b-toggle.collapse-17-inner>
                      <p><b>MODALIDADES</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-17-inner">
                      <div class="line-height-one">
                        <p>Hora</p>
                        <p>Medio día</p>
                        <p>Día completo</p>
                      </div>
                    </b-collapse>
                    <div v-b-toggle.collapse-18-inner>
                      <p><b>TARIFAS</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-18-inner">
                      <div class="line-height-one">
                        <p>Hora<b class="right">0,5 - 0,7 UF</b></p>
                        <p>Medio día<b class="right">1 - 1,2 UF</b></p>
                        <p style="margin-bottom: 1.2rem;">Día Completo<b class="right">1,4 - 1,7 UF</b></p>

                        <p style="line-height: 1rem;">Para conocer nuestras salas de reuniones, <a href="https://igniscowork.cl/salas_reuniones.pdf" target="_blank">descargue nuestro brochure.</a></p>
                        <p style="line-height: 1rem; font-size: 0.85rem;"><i>*Todos los valores tienen el IVA incluido.</i></p>
                      </div>
                    </b-collapse>
                  </b-card-text>
                </div>
              </b-card-body>
              <!-- <b-button v-b-modal.modal-1 @click="actualizarImagenes(4)" variant="light" class="w-100">VER FOTOS</b-button> -->
            </b-card>
          </b-col>
          <b-col class="mb-2 d-flex justify-content-center" sm="6" md="6" lg="4" xl="4">
            <b-card
              no-body
              bg-variant="light"
              class="no-borders"
            >
              <b-card-header class="d-flex align-items-center justify-content-center card-header-height">
                <h4 class="mb-0 color-naranjo">OFICINAS <b>PRIVADAS</b></h4>
              </b-card-header>
              <b-card-img :src="require('../../src/assets/16-9_4ofprivada_peq.jpg')" alt="Image" bottom></b-card-img>
              <b-card-body class="flex-column h-100" style="overflow-y: auto;">
                <div class="d-flex align-items-center">
                  <b-card-text>
                    <div v-b-toggle.collapse-19-inner>
                      <p><b>DESCRIPCIÓN</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-19-inner">
                      <p>Contamos con tres oficinas privadas diferentes: Prisma, Sinergia y Crisol. Cálidas y bien equipadas, cada una de ellas posee diferentes características para atender necesidades específicas. 
                        Para conocerlas en detalle <a href="https://igniscowork.cl/oficinas_privadas.pdf" target="_blank">descargue nuestro brochure.</a></p>
                    </b-collapse>
                    <div v-b-toggle.collapse-20-inner>
                      <p><b>HORARIO</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-20-inner">
                      <p>Lunes a Viernes, de 9h a 18h.</p>
                    </b-collapse>
                    <div v-b-toggle.collapse-21-inner>
                      <p><b>SERVICIOS INCLUIDOS</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-21-inner">
                      <div class="line-height-one">
                        <p>Acceso a internet de alta velocidad.</p>
                        <p>Acceso a escáner.</p>
                        <p>Agua mineral, té y café ilimitados.</p>
                        <p>Uso de cafetería.</p>
                        <p>Estufa eléctrica</p>
                        <p style="margin-bottom: 31px;">Seguridad y limpieza diaria.</p>
                      </div>
                    </b-collapse>
                    <div v-b-toggle.collapse-22-inner>
                      <p><b>SERVICIOS EXTRA*</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-22-inner">
                      <div class="line-height-one">
                        <p>Horario extendido 24/7.</p>
                        <p>Acceso biométrico con huella digital y llave personal.</p>
                        <p>Impresiones</p>
                        <p>Uso de sala de reuniones.</p>
                        <p>Participación en eventos.</p>
                        <p>Estacionamiento.</p>
                        <p>Oficina Virtual.</p>

                        <p style="line-height: 1rem; font-size: 0.85rem;"><i>*Servicios extra: Valor de servicios no incluidos en tarifa base. Servicios sujetos a disponibilidad.</i></p>
                      </div>
                    </b-collapse>
                    <div v-b-toggle.collapse-23-inner>
                      <p><b>MODALIDADES</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-23-inner">
                      <div class="line-height-one">
                        <p>Hora</p>
                        <p>Medio día</p>
                        <p>Pase diario</p>
                        <p>Pase semanal</p>
                        <p>Plan mensual</p>
                      </div>
                    </b-collapse>
                    <div v-b-toggle.collapse-24-inner>
                      <p><b>TARIFAS</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-24-inner">
                      <div class="line-height-one">
                        <p>Hora <b class="right">0,3 UF</b></p>
                        <p>Medio día <b class="right">0,6 UF</b></p>
                        <p>Pase diario <b class="right">1 - 1,2 UF</b></p>
                        <p>Pase semanal <b class="right">3,9 - 4,2 UF</b></p>
                        <p style="margin-bottom: 1.2rem;">Pase mensual <b class="right">11 - 15 UF</b></p>

                        <p style="line-height: 1rem;">Para información detallada sobre las tarifas, <a href="https://igniscowork.cl/oficinas_privadas.pdf" target="_blank">descargue nuestro brochure.</a></p>
                        <p style="line-height: 1rem; font-size: 0.85rem;"><i>*Todos los valores tienen el IVA incluido.</i></p>
                      </div>
                    </b-collapse>
                  </b-card-text>
                </div>
              </b-card-body>
              <!-- <b-button v-b-modal.modal-1 @click="actualizarImagenes(3)" variant="light" class="w-100">VER FOTOS</b-button> -->
            </b-card>
          </b-col>
          <b-col class="mb-2 d-flex justify-content-center" sm="6" md="6" lg="4" xl="4">
            <b-card
              no-body
              bg-variant="light"
              class="no-borders"
            >
              <b-card-header class="d-flex align-items-center justify-content-center card-header-height">
                <h4 class="mb-0 color-naranjo">CAFETERÍA <b>EL RELAJO</b></h4>
              </b-card-header>
              <b-card-img :src="require('../../src/assets/16-9_5relajo_peqjpg.jpg')" alt="Image" bottom></b-card-img>
              <b-card-body class="flex-column h-100" style="overflow-y: auto;">
                <div class="d-flex align-items-center">
                  <b-card-text>
                    <div v-b-toggle.collapse-25-inner>
                      <p><b>DESCRIPCIÓN</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-25-inner">
                      <p>Espacio de relajo de uso exclusivo de nuestra comunidad, y principal punto de encuentro de nuestro cowork. Ideal 
                        para tomarte una pausa durante la jornada y crear redes con otros coworkers.</p>
                    </b-collapse>
                    <div v-b-toggle.collapse-26-inner>
                      <p><b>HORARIO</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-26-inner">
                      <p>24/7</p>
                    </b-collapse>
                    <div v-b-toggle.collapse-27-inner>
                      <p><b>SERVICIOS INCLUIDOS</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-27-inner">
                      <div class="line-height-one">
                        <p>Agua mineral, té y café ilimitados.</p>
                        <p>Frigobar y microondas.</p>
                        <p>Hervidor eléctrico.</p>
                        <p style="margin-bottom: 31px;">Seguridad y limpieza diaria.</p>

                        <p style="line-height: 1rem; font-size: 0.85rem;"><i>Servicio no incluido: Limpieza de loza.</i></p>
                      </div>
                    </b-collapse>
                  </b-card-text>
                </div>
              </b-card-body>
              <!-- <b-button v-b-modal.modal-1 @click="actualizarImagenes(5)" variant="light" class="w-100">VER FOTOS</b-button> -->
            </b-card>
          </b-col>
          <b-col class="mb-2 d-flex justify-content-center" sm="6" md="6" lg="4" xl="4">
            <b-card
              no-body
              bg-variant="light"
              class="no-borders"
            >
              <b-card-header class="d-flex align-items-center justify-content-center card-header-height">
                <h4 class="mb-0 color-naranjo">OFICINA <b>VIRTUAL</b></h4>
              </b-card-header>
              <b-card-img :src="require('../../src/assets/16-9_6ofvirtual_peq.jpg')" alt="Image" bottom></b-card-img>
              <b-card-body class="flex-column h-100" style="overflow-y: auto;">
                <div class="d-flex align-items-center">
                  <b-card-text>
                    <div v-b-toggle.collapse-28-inner>
                      <p><b>DESCRIPCIÓN</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-28-inner">
                      <p>La dirección es la principal cara que tiene una empresa ante sus clientes, proveedores y demás empresas. Estamos 
                        ubicados en la principal arteria de la ciudad jardín, y te ofrecemos nuestra dirección para que la utilices como 
                        dirección tributaria, comercial y para recepción de correspondencia.</p>
                    </b-collapse>
                    <div v-b-toggle.collapse-29-inner>
                      <p><b>SERVICIOS INCLUIDOS</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-29-inner">
                      <div class="line-height-one">
                        <p style="line-height: 1rem;">Dirección Tributaria en el SII, en tu inicio de actividades y tus facturas, boletas y otros documentos tributarios.</p>
                        <p style="line-height: 1rem;">Dirección Comercial en la Municipalidad de Viña del Mar, para obtener patente comercial.</p>
                        <p style="line-height: 1rem;">Recepción de correspondencia física, facturas, página web, tarjetas de presentación, publicidad, etc.</p>
                        <p style="line-height: 1rem;">Uso de sala de reuniones (1h gratuita x mes).</p>
                        <p>Participación en eventos.</p>
                        <p style="margin-bottom: 31px;">Descuentos en otros servicios.</p>
                      </div>
                    </b-collapse>
                    <div v-b-toggle.collapse-30-inner>
                      <p><b>MODALIDADES</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-30-inner">
                      <div class="line-height-one">
                        <p>Semestral</p>
                        <p>Anual</p>
                      </div>
                    </b-collapse>
                    <div v-b-toggle.collapse-31-inner>
                      <p><b>TARIFAS</b> <font-awesome-icon class="ml-2 color-naranjo" :icon="['fa', 'plus-circle']" /></p>
                    </div>
                    <b-collapse id="collapse-31-inner">
                      <div class="line-height-one">
                        <p>Semestral <b class="right">3 UF</b></p>
                        <p style="margin-bottom: 1.2rem;">Anual <b class="right">5,5 UF</b></p>

                        <p style="line-height: 1rem;">Para información sobre nuestros planes, <a href="https://igniscowork.cl/6oficina_virtual.pdf" target="_blank">descargue nuestro brochure.</a></p>
                        <p style="line-height: 1rem; font-size: 0.85rem;"><i>*Todos los valores tienen el IVA incluido.</i></p>
                      </div>
                    </b-collapse>
                  </b-card-text>
                </div>
              </b-card-body>
              <!-- <b-button v-b-modal.modal-1 @click="actualizarImagenes(6)" variant="light" class="w-100">VER FOTOS</b-button> -->
            </b-card>
          </b-col>
        </b-row>
        <b-row id="prestaciones" class="mb-md-4 mb-3 mx-1 d-flex justify-content-center">
          <b-col 
            v-b-tooltip.hover title="Estamos conectados con fibra óptica de Entel"
            class="d-flex justify-content-center align-items-center" 
            :class="internetColor == 'color' ? 'color-naranjo' : 'black-color'" 
            md="3" 
            @mouseover="internetHover = true" 
            @mouseleave="internetHover = false">
              <font-awesome-icon size="2x" :icon="['fa', 'wifi']" />
              <p>INTERNET DE ALTA VELOCIDAD</p>
          </b-col>
          <b-col 
            v-b-tooltip.hover title="En nuestra cafetería El Relajo encontrarás agua mineral, té y café ilimitados"
            class="d-flex justify-content-center align-items-center" 
            :class="cafeColor == 'color' ? 'color-naranjo' : 'black-color'" 
            md="3" 
            @mouseover="cafeHover = true" 
            @mouseleave="cafeHover = false">
              <font-awesome-icon size="2x" :icon="['fa', 'mug-hot']" />
              <p>CAFÉ ILIMITADO</p>
          </b-col>
          <b-col 
            v-b-tooltip.hover title="Escáner gratuito / Impresiones gratuitas según plan"
            class="d-flex justify-content-center align-items-center" 
            :class="impresoraColor == 'color' ? 'color-naranjo' : 'black-color'" 
            md="3" 
            @mouseover="impresoraHover = true" 
            @mouseleave="impresoraHover = false">
              <font-awesome-icon size="2x" :icon="['fa', 'print']" />
              <p>MULTIFUNCIONAL</p>
          </b-col>
          <b-col 
            v-b-tooltip.hover title="Exclusivo planes, consulta las condiciones"
            class="d-flex justify-content-center align-items-center" 
            :class="relojColor == 'color' ? 'color-naranjo' : 'black-color'" 
            md="3" 
            @mouseover="relojHover = true" 
            @mouseleave="relojHover = false">
              <font-awesome-icon size="2x" :icon="['fa', 'clock']" />
              <p>ACCESO 24/7 <font-awesome-icon class="ml-2" size="sm" :icon="['fa', 'info-circle']" /></p>
          </b-col>
          <b-col 
            v-b-tooltip.hover title="Exclusivo plan mensual completo en sala El Encuentro"
            class="d-flex justify-content-center align-items-center" 
            :class="archivadorColor == 'color' ? 'color-naranjo' : 'black-color'" 
            md="4" 
            @mouseover="archivadorHover = true" 
            @mouseleave="archivadorHover = false">
              <font-awesome-icon size="2x" :icon="['fa', 'archive']" />
              <p>LOCKERS</p>
          </b-col>
          <b-col 
            v-b-tooltip.hover title="Exclusivo comunidad, consulta tarifas y condiciones"
            class="d-flex justify-content-center align-items-center" 
            :class="parkingColor == 'color' ? 'color-naranjo' : 'black-color'" 
            md="4" 
            @mouseover="parkingHover = true" 
            @mouseleave="parkingHover = false">
              <font-awesome-icon size="2x" :icon="['fa', 'parking']" />
              <p>ESTACIONAMIENTOS</p>
          </b-col>
          <b-col 
            v-b-tooltip.hover title="Sé parte de la comunidad Ignis y participa de nuestros eventos"
            class="d-flex justify-content-center align-items-center" 
            :class="eventosColor == 'color' ? 'color-naranjo' : 'black-color'" 
            md="4" 
            @mouseover="eventosHover = true" 
            @mouseleave="eventosHover = false">
              <font-awesome-icon size="2x" :icon="['fa', 'calendar']" />
              <p>EVENTOS</p>
          </b-col>
        </b-row>
        <b-row class="pt-3" id="banner-fotos">
                <b-col class="m-0 p-0">
                    <b-img fluid :src="require('../../src/assets/banner1.jpg')"></b-img>
                </b-col>
                <b-col class="m-0 p-0 d-none d-md-block" md="4">
                    <b-img fluid :src="require('../../src/assets/banner2.jpg')"></b-img>
                </b-col>
                <b-col class="m-0 p-0 d-none d-md-block" md="4">
                    <b-img fluid :src="require('../../src/assets/banner3.jpg')"></b-img>
                </b-col>
            </b-row>
      </b-container>
    </section>
</template>

<script>
export default {
    name: 'Servicios',
    data() {
      return {
        show: false,
        galeriaActual: 1,
        internetHover: false,
        cafeHover: false,
        relojHover: false,
        archivadorHover: false,
        impresoraHover: false,
        parkingHover: false,
        eventosHover: false
      }
    },
    methods: {
      actualizarImagenes(servicio) {
        this.galeriaActual = servicio;
      }
    },
    computed: {
      internetColor: function () {
        return this.internetHover ? 'color' : 'negro'
      },
      internetColorFontAwesome: function () {
        return this.internetHover ? '' : 'black'
      },
      cafeColor: function () {
        return this.cafeHover ? 'color' : 'negro'
      },
      relojColor: function () {
        return this.relojHover ? 'color' : 'negro'
      },
      archivadorColor: function () {
        return this.archivadorHover ? 'color' : 'negro'
      },
      impresoraColor: function () {
        return this.impresoraHover ? 'color' : 'negro'
      },
      parkingColor: function () {
        return this.parkingHover ? 'color' : 'negro'
      },
      eventosColor: function () {
        return this.eventosHover ? 'color' : 'negro'
      }
    }
}
</script>

<style>
#servicios {
  background-color: white;
}

#servicios h4 {
  font-size: 1.4rem;
  text-align: center;
}

#servicios hr {
  display: block; 
  height: 1px;
  border: 0; 
  border-top: 1px solid #F4A836;
  margin: 1em 0;
  padding: 0;
}

#servicios ul {
  padding: 1rem;
}

#servicios #cards img {
  max-height: 300px;
}

#prestaciones p {
  margin-top: 1rem;
  margin-left: 1rem;
}

#prestaciones img {
  height: 40px;
}

#banner-fotos img {
    background-size: cover;
    background-position: center center;
}

.servicios-title {
    font-size: 36px;
    color: #F4A836;
}

.black-color {
  color: black;
}

.no-borders {
  border: none !important;
}

.card-height {
  min-height: 650px !important;
  max-height: 650px !important;
}

.card-header-height {
  min-height: 75px !important;
  max-height: 125px !important;
}

.h-100-px {
  height: 100px;
}

.modal-content {
  background-color: transparent !important;
  border-color: transparent !important;
}

.modal-backdrop
{
  opacity: 0.7 !important;
}

.btn-secondary {
  background-color: transparent !important;
  border-color: transparent !important;
}

@media screen and (max-width: 1399px) {
    .margen-cards-servicios {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
}

@media screen and (min-width: 1400px) {
    .margen-cards-servicios {
      margin-right: 20% !important;
      margin-left: 20% !important;
    }
}

.color-naranjo {
  color: #F4A836;
}

.line-height-one {
  line-height: 1rem;
}

.left {
  float: left;
}

.right {
  float: right;
}
</style>