import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../../src/components/Home';
import Nosotros from '../../src/components/Nosotros';
import Servicios from '../../src/components/Servicios';
import Redes from '../../src/components/Redes';
import Contacto from '../../src/components/Contacto';

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Inicio",
    component: Home
  },
  {
    path: "/nosotros",
    name: "Nosotros",
    component: Nosotros
  },
  {
    path: "/servicios",
    name: "Servicios",
    component: Servicios
  },
  {
    path: "/redes",
    name: "Redes",
    component: Redes
  },
  {
    path: "/contacto",
    name: "Contacto",
    component: Contacto
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 100 }
      }
    }
    return { x: 0, y: 0 }
  },
  base: process.env.BASE_URL,
  routes
})

export default router
