<template>
    <section id="redes">
        <b-container fluid class="py-3 p-0">
            <b-row class="d-flex justify-content-center mb-3 mb-md-4 redes-title text-center mx-3 mx-md-0">
                REDES Y BENEFICIOS
            </b-row>
            <b-row class="d-flex justify-content-center align-items-center text-center mx-3 mx-md-0">
            <b-col md="6">
                <p>
                    Estamos siempre buscando nuevos vínculos de colaboración con instituciones públicas y/o privadas, 
                    que compartan nuestro objetivo de contribuir a la ampliación de un ecosistema de emprendimiento e 
                    innovación en la región. Actualmente, <b>Ignis Cowork</b> tiene convenios con <b>EUROCHILE</b>, <b>DIN/PUCV</b>, <b>3IE/USM</b>, y la <b>Fundación Renueva Mujer</b>
                    ofreciendo diversos beneficios para los socios/clientes de ambas partes.
                </p>
            </b-col>
            </b-row>
            <b-row class="d-flex justify-content-md-center ml-md-5 mr-md-5 ml-1 mr-1" align-v="center">
                <b-col class="mb-3 d-flex justify-content-center" md="6">
                    <a href="https://3ie.usm.cl/" target="_blank">
                        <b-img fluid :src="require('../../src/assets/3ielogo.webp')" style="max-height:200px;"></b-img>
                    </a>
                </b-col>
                <b-col md="6">
                    <div class="underline uppercase">
                        3IE
                    </div>
                    <p>
                        Es la incubadora de negocios de la Universidad Santa María. Promueve el emprendimiento y la innovación a través de programas para emprendedores y compañías que quieran 
                        revolucionar la industria tecnológica. Realiza su misión a través de las áreas de incubación, aceleración, formación y networking.
                    </p>
                    <p style="margin-top: -10px;">Conoce los beneficios <a href="https://igniscowork.cl/convenio_3IE_2024.pdf" target="_blank">aquí</a>.</p>
                </b-col>
            </b-row>
            <b-row class="d-flex justify-content-md-center ml-md-5 mr-md-5 ml-1 mr-1" align-v="center">
                <b-col class="mb-3 d-flex justify-content-center" md="6">
                    <a href="https://www.eurochile.cl/" target="_blank">
                        <b-img fluid :src="require('../../src/assets/euro_logo.webp')"></b-img>
                    </a>
                </b-col>
                <b-col md="6">
                    <div class="underline uppercase">
                        EUROCHILE
                    </div>
                    <p>
                        La misión de EuroChile es crear, promover y consolidar vínculos económicos, comerciales y tecnológicos entre pequeñas y medianas empresas e instituciones chilenas y de las 
                        naciones que integran la Unión Europea, tanto públicas como privadas, siendo un facilitador del proceso de internacionalización de las Pymes y del fortalecimiento de las 
                        instituciones de apoyo.
                    </p>
                </b-col>
            </b-row>
            <b-row class="d-flex justify-content-md-center ml-md-5 mr-md-5 ml-1 mr-1" align-v="center">
                <b-col class="mb-3 d-flex justify-content-center" md="6">
                    <a href="https://www.eurochile.cl/" target="_blank">
                        <b-img fluid style="max-height:150px;" :src="require('../../src/assets/logo_renueva_mujer.png')"></b-img>
                    </a>
                </b-col>
                <b-col md="6">
                    <div class="underline uppercase">
                        Fundación Renueva Mujer
                    </div>
                    <p>
                        La Fundación Renueva Mujer es una organización sin fines de lucro que aspira a mejorar la calidad de vida de las mujeres en todos los ámbitos en que ellas se desarrollen. 
                        Para esto, promueve instancias de crecimiento personal y estilos de vida saludables, abordando temas como: empleabilidad, equidad de género, emprendimiento, deporte, 
                        recreación, cultura, turismo y medio ambiente.
                    </p>
                    <p style="margin-top: -10px;">Conoce los beneficios <a href="https://igniscowork.cl/convenio_RenuevaMujer_2024.pdf" target="_blank">aquí</a>.</p>
                </b-col>
            </b-row>
            <b-row class="d-flex justify-content-md-center ml-md-5 mr-md-5 ml-1 mr-1" align-v="center">
                <b-col class="mb-3 d-flex justify-content-center" md="6">
                    <a href="https://www.pucv.cl/uuaa/servicios-transversales" target="_blank">
                        <b-img fluid :src="require('../../src/assets/LOGO-PUCV.webp')" style="max-height:200px;"></b-img>
                    </a>
                </b-col>
                <b-col md="6">
                    <div class="underline uppercase">
                        Pontificia Universidad Católica de Valparaíso
                    </div>
                    <p>
                        La Pontificia Universidad Católica de Valparaíso, a través de la Vicerrectoría de Investigación y Estudios Avanzados y su Dirección de Incubación y Negocios, tiene por 
                        objetivo fomentar la creación de empresas innovadoras de la comunidad universitaria y su entorno, por medio del despliegue territorial, equipo multidisciplinario, redes de 
                        colaboración y aplicación de metodologías de vanguardia, contribuyendo así al desarrollo económico, social y cultural del país. Esta Vicerrectoría se encuentra conformada 
                        por la Incubadora de Negocios Chrysalis, el Centro de Emprendimiento e Innovación Abierta en Industrias Inteligentes (Ceinnova PUCV), el Valparaíso Makerspace y el Cowork Atacama.
                    </p>
                    <p style="margin-top: -10px;">Conoce los beneficios <a href="https://igniscowork.cl/CONVENIO_PUCV_2023.pdf" target="_blank">aquí</a>.</p>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
export default {
    name: 'Redes'
}
</script>

<style>
#redes {
  background-color: #EEEEEE;
}

.redes-title {
    font-size: 36px;
    color: #F4A836;
}

.redes-subtitle {
    font-size: 18px;
}

.uppercase {
    text-transform: uppercase;
}
</style>