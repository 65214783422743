<template>
    <section id="home">
        <b-modal centered
            v-model="show"
            :size="modalSize"
            id="modal-1"
            hide-header
            hide-footer
        >
            <b-row>
                <b-carousel
                    id="carousel-1"
                    v-model="slide"
                    :interval="7000"
                    controls
                    indicators
                    background="#ababab"
                    style="text-shadow: 1px 1px 2px #333;"
                    @sliding-start="onSlideStart"
                    @sliding-end="onSlideEnd"
                    >
                    <b-carousel-slide>
                        <template #img>
                        <img
                            class="d-block img-fluid w-100"
                            :src="require('../../src/assets/aniversario-popup.webp')"
                            alt="image slot"
                        >
                        </template>
                    </b-carousel-slide>

                    <b-carousel-slide v-for="n in 11">
                        <template #img>
                        <img
                            class="d-block img-fluid w-100"
                            :src="require('../../src/assets/galeria-fotos/' + n + '.webp')"
                            alt="image slot"
                        >
                        </template>
                    </b-carousel-slide>

                    </b-carousel>
            </b-row>
            <b-row class="d-flex justify-content-center">
                <b-button @click="show=false" class="cerrar-btn">CERRAR</b-button>
            </b-row>
        </b-modal>
        <b-container fluid class="p-0">
            <b-row class="d-flex justify-content-center">
                <b-col class="d-flex justify-content-center ml-2 mr-4">
                    <b-img fluid class="logo-central" :src="require('../../src/assets/logo_copia.svg')"></b-img>
                </b-col>
            </b-row>
            <!-- <b-row class="d-flex justify-content-center pt-5">
                <h1 class="mx-5">TRABAJA SIN DISTRACCIONES</h1>
            </b-row>
            <b-row class="d-flex justify-content-center">
                <h5 class="mx-5">Te entregamos todas las facilidades e infraestructura para trabajar tranquilamente</h5>
            </b-row> -->
        </b-container>
    </section>
</template>

<script>
export default {
    name: 'Home',
    data() {
        return {
            show: false,
            slide: 0,
            sliding: null
        }
    },
    computed: {
        modalSize() {
            return this.slide === 10 ? 'lg' : 'xl'
        }
    },
    methods: {
        onSlideStart(slide) {
            this.sliding = true
        },
        onSlideEnd(slide) {
            this.sliding = false
        }
    }
}
</script>

<style>
#home {
    background-image: url("../../src/assets/portada.webp"), linear-gradient(180deg, #000000 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    background-blend-mode: darken, normal, normal;
    background-size: cover;
    background-position: center center;
    color: white;
    text-align: center;
}

@media screen and (max-width: 768px) {
    #home {
        background-image: url("../../src/assets/portada_chica.webp"), linear-gradient(180deg, #000000 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    }
}

#home h1 {
    font-size: 2.5rem;
}

#home h5 {
    font-size: 1rem;
}

.logo-central {
    margin-top: 65px;
    height: 75vh !important;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='white' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") !important;
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='white' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") !important;
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
}

.carousel-indicators {
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
}
</style>