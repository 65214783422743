<template>
    <section id="nosotros">
        <b-container fluid class="pt-3 p-0">
            <b-row class="d-flex justify-content-center mb-3 mb-md-4 nosotros-title text-center mx-3 mx-md-0">
                QUÉ ES IGNIS COWORK
            </b-row>
            <b-row class="d-flex justify-content-center text-center mb-3 mb-md-4 mx-3 mx-md-0">
                <b-col md="6">
                    <p style="margin-bottom:0;">
                        <b>Ignis Cowork</b> es un espacio de trabajo compartido, que ofrece todas las 
                        comodidades, facilidades e infraestructura para realizar tus actividades 
                        laborales, sin distracciones ni preocupaciones.
                    </p>
                    <p>
                        Nuestra misión es ofrecer espacios y redes de contacto profesional para 
                        emprendedores que recién comienzan con sus actividades, 
                        así como para las pequeñas empresas que desean crecer.
                    </p>
                </b-col>
            </b-row>
            <b-row class="d-flex justify-content-md-center ml-md-5 mr-md-5 ml-1 mr-1">
                <b-col md="6">
                    <div class="underline bold">
                        COMUNIDAD
                    </div>
                    <p>
                        Formamos lazos y trabajamos en equipo, de esta forma nos educamos y aprendemos unos de otros.
                    </p>
                </b-col>
                <b-col md="6">
                    <div class="underline bold">
                        CONFIANZA
                    </div>
                    <p>
                        Te acompañamos a cada paso dando lo mejor de nosotros, brindando la seguridad que tu empresa o negocio necesita.
                    </p>
                </b-col>
            </b-row>
            <b-row class="d-flex justify-content-md-center ml-md-5 mr-md-5 ml-1 mr-1">
                <b-col md="6">
                    <div class="underline bold">
                        RESPETO
                    </div>
                    <p>
                        El respeto por nuestros clientes nos ayuda a generar una buena comunicación, lo que es fundamental para mantener vínculos de largo plazo.
                    </p>
                </b-col>
                <b-col md="6">
                    <div class="underline bold">
                        INNOVACIÓN
                    </div>
                    <p>
                        Buscamos que cada una de las experiencias que tengas en nuestro cowork sea un desafío para nosotros, entregándote una vivencia de calidad y novedosa.
                    </p>
                </b-col>
            </b-row>            
            <b-row class="d-flex justify-content-md-center ml-md-5 mr-md-5 ml-1 mr-1">
                <b-col md="6">
                    <div class="underline bold">
                        CALIDEZ
                    </div>
                    <p>
                        Tenemos un lugar cómodo y equipado para que puedas estar relajado y concentrarte en la realización de tus trabajos.
                    </p>
                </b-col>
                <b-col md="6">
                    <div class="underline bold">
                        COLABORACIÓN
                    </div>
                    <p>
                        Contamos con el apoyo de instituciones, empresas y organizaciones que confían en desarrollar sus trabajos en nuestro cowork y constituyen puentes hacia el mundo empresarial.
                    </p>
                </b-col>
            </b-row>
            <b-row class="pt-3" id="nosotros-fotos">
                <b-col class="m-0 p-0">
                    <b-img fluid :src="require('../../src/assets/foto_panorama.webp')"></b-img>
                </b-col>
                <!-- <b-col class="m-0 p-0 d-none d-md-block" md="4">
                    <b-img fluid :src="require('../../src/assets/silencioso.webp')"></b-img>
                </b-col>
                <b-col class="m-0 p-0 d-none d-md-block" md="4">
                    <b-img fluid :src="require('../../src/assets/reuniones_2.webp')"></b-img>
                </b-col> -->
            </b-row>
        </b-container>
    </section>
</template>

<script>
export default {
    name: 'Nosotros'
}
</script>

<style>

#nosotros {
    background-color: #EEEEEE;   
}

#nosotros p {
    width: 98%;
}

#nosotros-fotos img {
    background-size: cover;
    background-position: center center;
}

.nosotros-title {
    font-size: 36px;
    color: #F4A836;
}

.trabajando {
    font-size: 24px;
}

.bold {
    font-weight: bold;
}
</style>