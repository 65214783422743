<template>
  <div id="app">
    <link rel="preconnect" href="https://fonts.gstatic.com">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet"> 
    <AppHeader :header-style="headerStyle" />
    <router-view v-observe-visibility="visibilityChanged" />
    <Nosotros />
    <Servicios />
    <Redes />
    <Contacto />
  </div>
</template>

<script>
import AppHeader from '../src/components/AppHeader';
import Home from '../src/components/Home';
import Nosotros from '../src/components/Nosotros';
import Servicios from '../src/components/Servicios';
import Redes from '../src/components/Redes';
import Contacto from '../src/components/Contacto';

export default {
  components: {
    AppHeader,
    Home,
    Nosotros,
    Servicios,
    Redes,
    Contacto
  },
  data() {
    return {
      headerStyle: 0
    }
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      if (isVisible)
        this.headerStyle = 0
      else
        this.headerStyle = 1
    }
  }
}
</script>

<style>
#app {
  font-family: Montserrat, sans-serif;
  color: #2c3e50;
  overflow-x: hidden;
}

#app section {
  width: 100vw;
}

#home {
  height: 100vh;
  display: table;
}

#home div {
  display: table-cell;
  vertical-align: middle;
}

.underline {
  display: inline-block;
  width: 98%;
  text-decoration: none;
  border-bottom: 2px solid #F4A836;
}
</style>
