<template>
    <div>
        <header>
            <b-navbar toggleable="md" fixed="top" :class="background">
                <b-navbar-brand to='/' class="mt-2 p-0">
                    <router-link to="/"><img :class="logo" alt="Ignis Cowork" :src="require('../../src/assets/IGNIS_cowork_horizontal_' + logoStyle + '.svg')"></router-link>
                </b-navbar-brand>
                <b-navbar-toggle target="navbarCollapse" label="Activar navegación">
                    <template #default="{ expanded }">
                    <b-icon v-if="expanded" icon="chevron-up" :variant="colorVariant"></b-icon>
                    <b-icon v-else icon="chevron-down" :variant="colorVariant"></b-icon>
                </template>
                </b-navbar-toggle>
                <b-collapse id="navbarCollapse" is-nav>
                    <b-navbar-nav :class="activeClass" class="mx-auto">
                        <b-nav-item center>
                            <router-link to="/">Inicio</router-link>
                        </b-nav-item>
                        <b-nav-item>
                            <router-link :to="{ path: '/', hash: '#nosotros' }">Nosotros</router-link>
                        </b-nav-item>
                        <b-nav-item>
                            <router-link :to="{ path: '/', hash: '#servicios' }">Servicios</router-link>
                        </b-nav-item>
                        <b-nav-item>
                            <router-link :to="{ path: '/', hash: '#redes' }">Redes</router-link>
                        </b-nav-item>
                        <a href="https://igniscowork.cl/tarifas_2024.pdf" target="_blank">Tarifas</a>
                         <!-- <div class="dropdown">
                            <button :class="dropbtn">VALORES</button>
                            <div class="dropdown-content" :class="backgroundColor">
                                <a href="https://igniscowork.cl/MODALIDADES Y PACKS_OFICIAL_MOD_UF.pdf" target="_blank">Modalidades</a>
                                <a href="https://igniscowork.cl/PRECIOS_MOD_UF-1.pdf" target="_blank">Valores</a>
                            </div>
                        </div>  -->
                        <a href="https://forms.gle/BN9d4XvyyYmYgB4SA" target="_blank">Contacto</a>
                    </b-navbar-nav>
                    <b-navbar-nav class="navbar-social d-flex mx-3">
                        <b-nav-item href="https://www.instagram.com/igniscowork/" target="_blank" class="mr-3 mr-md-0"><font-awesome-icon size="lg" :style="{ color: iconsColor }" :icon="['fab', 'instagram']" /></b-nav-item>
                        <b-nav-item href="https://www.facebook.com/igniscowork/" target="_blank" class="mr-3 mr-md-0"><font-awesome-icon size="lg" :style="{ color: iconsColor }" :icon="['fab', 'facebook-square']" /></b-nav-item>
                        <b-nav-item href="https://www.linkedin.com/company/ignis-cowork-chile/" target="_blank" class="mr-3 mr-md-0"><font-awesome-icon size="lg" :style="{ color: iconsColor }" :icon="['fab', 'linkedin']" /></b-nav-item>
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
        </header>
    </div>
</template>

<script>
export default {
    name: 'AppHeader',
    props: ['headerStyle'],
    computed: {
        iconsColor: function () {
            return this.headerStyle == 0 ? 'white' : 'black'
        },
        logoStyle: function () {
            return this.headerStyle == 0 ? 'blanco' : 'negro'
        },
        activeClass: function () {
            return this.headerStyle == 0 ? 'navbar-links-white' : 'navbar-links-black'
        },
        background: function () {
            return this.headerStyle == 0 ? '' : 'nav-background-grey'
        },
        logo: function () {
            return this.headerStyle == 0 ? 'logo-color' : 'logo-black'
        },
        dropbtn: function () {
            return this.headerStyle == 0 ? 'dropbtn-white' : 'dropbtn-black'
        },
        backgroundColor: function () {
            return this.headerStyle == 0 ? 'black-background' : 'white-background'
        },
        colorVariant: function () {
            return this.headerStyle == 0 ? 'light' : 'dark'
        }
    }
}
</script>

<style>
 /* Dropdown Button */
.dropbtn-white {
  background-color: rgba(0,0,0,0);
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropbtn-black {
  color: black;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropbtn-white:hover {
    color: #F4A836 !important;
}
.dropbtn-black:hover {
    color: #F4A836 !important;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

.white-background {
    background-color: white;
}

.black-background {
    background-color: black;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

.logo-color {
    height: 50px;
}

.logo-black {
    height: 50px;
}

.navbar-links-white a {
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 15px;
  text-shadow: 2px 2px black;
}

.navbar-links-black a {
  text-transform: uppercase;
  color: #000000;
  padding: 15px;
}

.navbar-links-white a.router-link-exact-active {
  color: #F4A836;
}

.navbar-links-black a.router-link-exact-active {
  color: #F4A836;
}

.navbar-links-white a:hover {
  color: #F4A836;
  text-decoration: none;
}

.navbar-links-black a:hover {
  color: #F4A836;
  text-decoration: none;
}

.navbar-social {
    flex-direction: row !important;
}

.nav-background-grey {
    background: #EEEEEE;
    opacity: 0.9;
}
</style>